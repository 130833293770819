
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './InputForm.css';
import axios from 'axios';


const InputForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const qrCodeFromState = location.state?.qrCode || '';
  const username = localStorage.getItem('username') || 'Guest'; // Lấy username từ localStorage
  const [message, setMessage] = useState('');

  const [formData, setFormData] = useState({
    powderType: '',
    supplyPlace: '',
    quantity: '',
    qrCode: qrCodeFromState,
    username: username,
  });

  const powderTypes = [
    'Ngải cứu', 'Hương đàn', 'Mùn cưa', 'Đặc biệt', 'Cò sen',
    'Bột thơm tán', 'Nhang ngải tán lại', 'Keo trắng', 'Keo đỏ',
    'Thuốc bắc', 'Bột quế xanh', 'Trầm', 'Tùng',
  ];
  const supplyPlaces = ['Chú Hùng', 'Chú Đa', 'Bà 6'];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://103.179.191.102:5000/api/nhap-bot-kho', {
          "loaibot": formData.powderType,
          "noicungcap": formData.supplyPlace,
          "soluong": formData.quantity,
          "maQR": formData.qrCode,
          "username": formData.username        
      });
      // Chuyển hướng sau 1 giây
      setTimeout(() => {
        navigate('/qr-scanner');
      }, 500); // 1000ms = 1 giây

      // navigate('/qr-scanner');
    } catch (error) {
      alert(`Lỗi khi gửi thông tin: ${error}`);
      navigate('/qr-scanner');
    }
  };

  return (
    <div className="input-form-container">
      <div className="welcome-text">
        Xin chào {username}
      </div>
      <h3>Nhập thông tin bột khô</h3>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="powderType">Tên loại bột:</label>
          <select
            id="powderType"
            name="powderType"
            value={formData.powderType}
            onChange={handleChange}
            required
          >
            <option value="" disabled>
              Chọn loại bột
            </option>
            {powderTypes.map((type, index) => (
              <option key={index} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="supplyPlace">Nơi cung cấp:</label>
          <select
            id="supplyPlace"
            name="supplyPlace"
            value={formData.supplyPlace}
            onChange={handleChange}
            required
          >
            <option value="" disabled>
              Chọn nơi cung cấp
            </option>
            {supplyPlaces.map((place, index) => (
              <option key={index} value={place}>
                {place}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="quantity">Số lượng:</label>
          <input
            type="number" // Đảm bảo bàn phím số được hiển thị
            inputMode="decimal" // Hỗ trợ nhập số thập phân
            id="quantity"
            name="quantity"
            value={formData.quantity}
            onChange={handleChange}
            required
            min="0"
            step="0.01" // Cho phép nhập giá trị thập phân
            placeholder="Nhập số thập phân"
          />
        </div>

        <div className="form-group">
          <label htmlFor="qrCode">Mã QR Code:</label>
          <input type="text" id="qrCode" name="qrCode" value={formData.qrCode} readOnly />
        </div>

        <button type="submit" className="btn btn-primary">
          Gửi thông tin
        </button>
      </form>
    </div>
  );
};

export default InputForm;
