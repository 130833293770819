// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
// import { Html5QrcodeScanner } from 'html5-qrcode';

// const QRScanner = () => {
//   const [scannedResult, setScannedResult] = useState(null);
//   const navigate = useNavigate();

//   const checkQRInDatabase = async (qrCode) => {
//     try {
//       const response = await axios.post('https://103.179.191.102:5000/api/qr/check-qr', {
//           qrCode
//         }
//         , {
//         headers: {
//           'Content-Type': 'application/json', // Cần thiết cho POST với JSON
//         },
//       });
//       if (response.status === 200) {
//         const data = response.data; // Lấy dữ liệu từ response
//         if (data.exists) {
//           // Nếu mã QR đã tồn tại, chuyển đến trang điền thông tin bột khô
//           navigate('/nhap-bot-kho', { state: { qrCode } });
//         } else {
//           alert('Mã QR chưa tồn tại trong hệ thống!');
//         }
//       } else {
//         console.error('Lỗi khi kiểm tra mã QR:', response.statusText);
//       }
//     } catch (error) {
//       console.error('Lỗi khi gọi API:', error);
//     }
//   };

//   const handleQRScan = (data) => {
//     if (data) {
//       setScannedResult(data);
//       // Kiểm tra mã QR trong cơ sở dữ liệu

//       checkQRInDatabase(data);
//     }
//   };

//   const handleQRScanError = (error) => {
//     console.error(error);
//   };

//   useEffect(() => {
//     const scanner = new Html5QrcodeScanner("reader", {
//       fps: 10,
//       qrbox: 250,
//     });
//     scanner.render(handleQRScan, handleQRScanError);

//     return () => {
//       scanner.clear(); // Dọn dẹp scanner khi component bị unmount
//     };
//   }, []);

//   return (
//     <div className="qr-scanner-page text-center">
//       <h3>Quét mã QR</h3>
//       <div id="reader" style={{ width: '100%', height: '900px' }}></div>
//       {scannedResult && (
//         <div className="mt-3">
//           <p><strong>Kết quả quét: </strong>{scannedResult}</p>
//         </div>
//       )}
//       <button className="btn btn-primary mt-3" onClick={() => navigate('/home')}>
//         Quay lại Home
//       </button>
//     </div>
//   );
// };

// export default QRScanner;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Html5QrcodeScanner } from 'html5-qrcode';

const QRScanner = () => {
  const [scannedResult, setScannedResult] = useState(null);
  const navigate = useNavigate();

  const checkQRInDatabase = async (qrCode) => {
    try {
      const response = await axios.post(
        'https://103.179.191.102:5000/api/qr/check-qr',
        { qrCode },
        {
          headers: {
            'Content-Type': 'application/json', // Cần thiết cho POST với JSON
          },
        }
      );
      if (response.status === 200) {
        const data = response.data;
        if (data.exists) {
          navigate('/nhap-bot-kho', { state: { qrCode } });
        } else {
          alert('Mã QR chưa tồn tại trong hệ thống!');
        }
      } else {
        console.error('Lỗi khi kiểm tra mã QR:', response.statusText);
      }
    } catch (error) {
      console.error('Lỗi khi gọi API:', error);
    }
  };

  const handleQRScan = (data) => {
    if (data) {
      setScannedResult(data);
      checkQRInDatabase(data);
    }
  };

  const handleQRScanError = (error) => {
    console.error(error);
  };

  useEffect(() => {
    let scanner;

    const initScanner = () => {
      scanner = new Html5QrcodeScanner("reader", {
        fps: 10,
        qrbox: 250,
      });
      scanner.render(handleQRScan, handleQRScanError);
    };

    initScanner();

    return () => {
      if (scanner) {
        scanner.clear().catch((err) => {
          console.error('Lỗi khi dọn dẹp scanner:', err);
        });
      }
    };
  }, []);

  return (
    <div className="qr-scanner-page text-center">
      <h3>Quét mã QR</h3>
      <div id="reader" style={{ width: '100%', height: '900px' }}></div>
      {scannedResult && (
        <div className="mt-3">
          <p>
            <strong>Kết quả quét: </strong>
            {scannedResult}
          </p>
        </div>
      )}
      <button className="btn btn-primary mt-3" onClick={() => navigate('/home')}>
        Quay lại Home
      </button>
    </div>
  );
};

export default QRScanner;
