import React from 'react';
import { useNavigate } from 'react-router-dom';
import './home.css';

const Home = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token'); // Xóa token khi đăng xuất
    navigate('/'); // Quay lại trang đăng nhập
  };

  return (
    <div className="home-page">
      <div className="home-container text-center">
        <h1>Trang Chủ Tuệ Hải Đường</h1>
        <p>Chào mừng bạn đến với hệ thống quản lý.</p>
        <div className="mt-4">
          <button
            className="btn btn-outline-success me-2"
            onClick={() => navigate('/qr-scanner')} // Điều hướng sang trang QRScanner
          >
            Nhập bột khô
          </button>
          <button
            className="btn btn-outline-info"
            onClick={() => alert('Trộn bột khô')}
          >
            Trộn bột khô
          </button>
        </div>

        <button className="btn btn-danger mt-4" onClick={handleLogout}>
          Đăng xuất
        </button>
      </div>
    </div>
  );
};

export default Home;
